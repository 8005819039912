type HomePost = {
    imageUrl: string,
    title: string,
    extract: string,
    link: string
}

export type HomePageExtras = {
    latestSocialPosts: HomePost[]
}

export const getHomeExtras = async () => {
    const response = await fetch(`/api/v1/home/extras`)
    return await response.json() as HomePageExtras  
} 
