import {Breadcrumb} from "../components/Breadcrumb/Breadcrumb";
import {useRecoilValue} from "recoil";
import {dataAtom} from "../atoms/dataAtom";
import React from "react";
import {format} from "date-fns"
import {it} from 'date-fns/locale/it'
import ShareBar from "../components/ShareBar/ShareBar";
import {sanitiseLinkTargets} from "../util/global";
import './article.scss'

export const Article = () => {
    const data = useRecoilValue(dataAtom)
    const articleContentRef = React.useRef<HTMLDivElement>(null);
    const onCrumbClick = (idx: number) => {
        if (idx === 1) {
            window.location.href = '/magazine'
        }
    }
    React.useEffect(() => {
        setTimeout(() => {
            if (articleContentRef.current) sanitiseLinkTargets(articleContentRef.current);
        }, 100)
    })
    const publishedDate = new Date(data?.published ?? Date.now())
    const shareUrl = window.location.href;
    const formattedPublishedDate = publishedDate.getFullYear() === new Date().getFullYear() ? format(publishedDate, "dd LLL yyyy", {locale: it}) : format(publishedDate, "dd LLL yyyy")
    const formattedPublishedTime = format(publishedDate, "HH:mm")
    return data ? <>
        <div className="articleContainer">
            <Breadcrumb crumbs={["Home", "Magazine", data.title]} onCrumbClick={onCrumbClick} clickableCrumbs={true}/>
            <div className="article-image-container"><img src={data.mainImage} alt={data.title}/></div>
            <h2 className={"articleTitle"}>{data.title}</h2>
            <div className="articleContent" dangerouslySetInnerHTML={{__html: data.content}} ref={articleContentRef}/>
            <div className="articleInfo">
                Pubblicato il {formattedPublishedDate} alle {formattedPublishedTime}
            </div>
            <ShareBar shareUrl={shareUrl} title={data.title}/>
        </div>
    </> : null;
}
