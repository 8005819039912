import React from "react";
import { goToHome } from '../../util/global'
import './breadcrumb.scss'

const Breadcrumb: React.FC<{
    crumbs: (string | undefined)[],
    onCrumbClick?: (idx: number) => void,
    clickableCrumbs?: boolean,
}> = ({ crumbs, onCrumbClick, clickableCrumbs }) =>
    {
        const onCrumbClickInternal = (idx: number) => {
            if (idx === 0) goToHome()
            onCrumbClick && onCrumbClick(idx)
        }
        return <div className='breadcrumbContainer'>
            {crumbs
                .filter(c => !!c)
                .map((c, i) =>
                    <span
                        key={c}
                        onClick={() => onCrumbClickInternal(i)}
                        className={"breadcrumb" + (clickableCrumbs ? " breadcrumbClickable" : "")}
                    >
                        <span className="breadcrumbContent">{c}</span> {i !== crumbs.length - 1 ? <>&raquo; </> : null}
                    </span>
                )}
        </div>
    }

export { Breadcrumb }