import {ArticleData} from "../../atoms/dataAtom";
import React from "react";
import {format} from "date-fns";
import { it } from 'date-fns/locale/it'
import './articleListingItem.scss'

const ArticleListingItem: React.FC<{ article: ArticleData }> = ({ article }) => {
    const publishedDate = new Date(article.published)
    const formattedPublishedDate = publishedDate.getFullYear() === new Date().getFullYear() ? format(publishedDate, "dd LLL", {locale: it}) : format(publishedDate, "dd LLL yyyy")
    const formattedPublishedTime = format(publishedDate, "HH:mm")
    return <a className="articleListingItem" href={`/magazine/${article.url}`}>
        <div style={{backgroundImage: `url("${article.mainImage}")`}} className="articleListingItemImage">&nbsp;</div>
        {article.title ? <div className="articleListingItemTitle">{article.title}</div> :
            <div className="articleListingItemTitle articleListingItemTitleEmpty">Nessun titolo</div>}
        <div className="articleListingItemDate">
            {formattedPublishedDate} {formattedPublishedTime}
            <div className="articleListingItemTags">
                {article.tags.filter(t => t !== "").slice(0, 3).map(tag => <span key={tag} className="articleListingItemTag">{tag}</span>)}
            </div>
        </div>
    </a>
}

export default ArticleListingItem