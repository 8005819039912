import React from "react";
import {useRecoilValue} from "recoil";
import {ArticlesListingData, dataAtom} from "../../atoms/dataAtom";
import {Breadcrumb} from "../../components/Breadcrumb/Breadcrumb";
import ArticleListingItem from "../../components/ArticleListingItem/ArticleListingItem";
import './articlesListing.scss'
import Pagination from "../../components/Pagination/Pagination";

const ArticlesListingContainer: React.FC = () => {
    const data = useRecoilValue(dataAtom)
    return data?.articles ? <ArticlesListing articlesData={data}></ArticlesListing> : null
}

const ArticlesListing: React.FC<{ articlesData: ArticlesListingData }> = ({articlesData}) => {
    const onPageClick = (page: number) => {
        window.location.href = `/magazine?page=${page}`
    }
    const onCrumbClick = (idx: number) => {
        if (idx === 1) {
            window.location.href = '/magazine'
        }
    }
    return <div className="articlesListingPageContainer">
        <Breadcrumb crumbs={["Home", "Magazine", `pagina ${articlesData.page}`]} onCrumbClick={onCrumbClick} clickableCrumbs={true} />
        <h1>Il magazine di Kibukibu.com</h1>
        <div className="articlesListingContainer">
            {articlesData.articles.map(article => <ArticleListingItem key={article.id} article={article}/>)}
        </div>
        <Pagination currentPage={articlesData.page} onPageClick={onPageClick} totalPages={articlesData.totalPages}/>
    </div>
}

export default ArticlesListingContainer;