import {useRecoilState, useRecoilValue} from 'recoil'
import {dataAtom, getCurrentSearch, getTotalPages} from '../../atoms/dataAtom'
import {gotoSearch} from '../../util/global'
import {Filters} from '../Filters/Filters'
import {ListingItem} from '../ListingItem/ListingItem'
import NoResults from '../../assets/oops.png'
import './listingContent.scss'
import {CategoryContent} from "../CategoryContent/CategoryContent";
import React from "react";
import Pagination from "../Pagination/Pagination";


const ListingWithResults = () => {
    const [data,] = useRecoilState(dataAtom)
    const totalPages = useRecoilValue(getTotalPages)
    const search = useRecoilValue(getCurrentSearch)

    const currentPage = search.page

    const onPageClick = (page: number) => {
        if (page !== currentPage) gotoSearch({...search, page})
    }

    return <>
        <CategoryContent/>
        <div className="listingPaginationDescription">
            Risultati
            da <b>{(currentPage - 1) * 10 + 1}</b> a <b>{Math.min(currentPage * 10, data?.total ?? 0)}</b> su <b>{data?.total}</b> per
            "{search.filter}"
        </div>
        {data?.toys?.map((t, i) =>
            <span key={t.id}>
                <ListingItem element={t}/>
            </span>
        )}
        <Pagination currentPage={currentPage} onPageClick={onPageClick} totalPages={totalPages}/>
    </>
}

const ListingNoResults = () =>
    <div className='listingPaginationDescription listingNoResults'>
        <img src={NoResults} alt="There are no results"/>
    </div>


const ListingContent = () => {
    const [data,] = useRecoilState(dataAtom)
    const anyResult = (data?.total ?? 0) > 0

    return <div className='listingContentContainer'>
        <div className="listingContentSidebar">
            <Filters/>
        </div>
        <div className="listingContentMain">
            {anyResult ? <ListingWithResults/> : <ListingNoResults/>}
        </div>
    </div>
}

export {ListingContent}