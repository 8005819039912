import { useRecoilState } from 'recoil'
import { dataAtom } from '../atoms/dataAtom'
import { CommunityAdv } from '../components/CommunityAdv/CommunityAdv'
import { Gallery } from '../components/Gallery/Gallery'
import { HomeClaim } from '../components/HomeClaim/HomeClaim'
import './home.scss'
import { HomeCategories } from "../components/HomeCategories/HomeCategories";
import { getHomeExtras, HomePageExtras } from "../atoms/homeExtras";
import { useEffect, useState } from 'react';

// const AdvHomeTop = () => <div className={"advContainer"}>
//     <script async
//             src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6596507552137958"
//             crossOrigin="anonymous"></script>
//     <ins className="adsbygoogle" style={{"display": "block"}} data-ad-client="ca-pub-6596507552137958"
//          data-ad-slot="6015691508" data-ad-format="auto" data-full-width-responsive="true"></ins>
//     <script>
//         (adsbygoogle = window.adsbygoogle || []).push({});
//     </script>
// </div>

// const AdvHomeTop = () => <div className={"advContainer advContainerMobile"}>
//     <a rel="sponsored" href="https://www.awin1.com/cread.php?s=3577397&v=25709&q=484252&r=1541567">
//         <img src="https://www.awin1.com/cshow.php?s=3577397&v=25709&q=484252&r=1541567" alt={"ad"} />
//     </a>
// </div>

const Home = () => {
    const [data,] = useRecoilState(dataAtom)
    const [homeExtras, setHomeExtras] = useState<HomePageExtras | null>(null)

    useEffect(() => { getHomeExtras().then(r => setHomeExtras(r)) }, [])

    const socialPosts =
        homeExtras?.latestSocialPosts
            .map(({ imageUrl, title, link }) => ({ imageUrl, link, name: title }))
        ?? [];

    const articles = data?.articles?.map(article => ({
        imageUrl: article.mainImage,
        link: `/magazine/${article.url}`,
        name: article.title,
        extract: ""
    }))
    const hasArticles = (articles?.length ?? 0) > 0
    return <div className='home'>
        <HomeClaim />
        {/*<AdvHomeTop />*/}
        <Gallery title="Nuovi Arrivi" elements={data?.newToys ?? []} />
        <Gallery title="I pi&ugrave; cliccati" elements={data?.mostClickedToys ?? []} />
        <CommunityAdv />
        <Gallery title={hasArticles ? "Ultimi articoli dal magazine" : "Ultimi post"} elements={articles} titledLayout={!hasArticles} />
        {hasArticles && <div className="showMoreArticles"><a href="/magazine">Vai al magazine</a></div>}
        <Gallery title="Social" elements={socialPosts} />
        <HomeCategories />
    </div>
}

export { Home }