import {atom, selector} from "recoil";
import {defaultSearch} from "../util/global";
import {Search} from "./searchAtom";

declare global {
    interface Window {
        pageData: PageData;
    }
}

export type PageData = HomePageData & ListingPageData & DetailPageData & CategoriesData & ArticleData & ArticlesListingData
export type Labels = { [key: string]: string }

type HomePageData = {
    mostClickedToys: HomeToy[],
    newToys: HomeToy[],
    labels: Labels,
    articles: ArticleData[],
    categories: Category[]
}

type Category = {
    name: string,
    slug: string
}

type CategoriesData = {
    categories: Category
}

type ListingPageData = {
    total: number,
    totalPages: number,
    toys: Toy[],
    merchants: Merchant[],
    labels: Labels,
    filterResult: FilterResult,
    request: SearchRequest,
    categoryContent?: CategoryContent
}

type CategoryContent = {
    category: string,
    content: string,
    images: string[],
    wiki?: string
}

type SearchRequest = {
    filter: string,
    fromPrice?: string,
    toPrice?: string,
    availability?: string[],
    merchant?: string[],
    page?: number,
    orderBy?: string,
    orderByDirection?: string
}

type FilterResult = {
    merchants: string[],
    maxPrice: number,
    minPrice: number
}

type DetailPageData = {
    toy: Toy,
    labels: Labels,
    brandRelatedToys: Toy[],
    flags: Record<string, boolean>
}

export type Merchant = {
    id: string,
    name: string
}

export type Toy = HomeToy & {
    description: string,
    mainImage: string,
    images: string[],
    price: number,
    merchant: string,
    inserted: string,
    clicks: number,
    availability: string
}

export type HomeToy = {
    imageUrl: string,
    name: string,
    id: string,
    link: string,
    internalLink: string
}

export type ArticleData = {
    id: string,
    title: string,
    url: string,
    mainImage: string,
    content: string,
    tags: string[],
    info: {
        description: string,
        keywords: string[]
    },
    published: string,
    created: string,
    updated: string
}

export type ArticlesListingData = {
    articles: ArticleData[],
    totalPages: number,
    page: number
}

export const dataAtom = atom<PageData | null>({
    key: "data",
    default: null,
})

export const hasDataLoadedSelector = selector({
    key: 'hasDataLoadedSelector',
    get: ({get}) => {
        const data = get(dataAtom);
        return !!data;
    },
});

export const getTotalPages = selector({
    key: 'getTotalPages',
    get: ({get}) => {
        return get(dataAtom)?.totalPages ?? 0
    }
})

export const getMerchantsMap = selector({
    key: 'getMerchantsMap',
    get: ({get}) => {
        return get(dataAtom)?.merchants?.reduce<Record<string, string>>((acc: Record<string, string>, curr: Merchant): Record<string, string> => ({
            ...acc,
            [curr.id]: curr.name
        }), {})
    }
})

export const getCurrentSearch = selector({
    key: "getCurrentSearch",
    get: ({get}) => {
        const dataSearch = get(dataAtom)?.request
        if (!dataSearch) return defaultSearch
        return {
            filter: dataSearch.filter,
            fromPrice: dataSearch.fromPrice ?? defaultSearch.fromPrice,
            toPrice: dataSearch.toPrice ?? defaultSearch.toPrice,
            availability: dataSearch.availability ?? defaultSearch.availability,
            merchant: dataSearch.merchant ?? defaultSearch.merchant,
            page: dataSearch.page ?? defaultSearch.page,
            orderBy: dataSearch.orderBy ?? defaultSearch.orderBy,
            orderByDirection: dataSearch.orderByDirection ?? defaultSearch.orderByDirection
        } as Search
    }
})