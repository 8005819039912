import {KeyboardEvent, useRef} from 'react'
import {GrFacebook, GrInstagram} from 'react-icons/gr'
import {MdMailOutline} from 'react-icons/md'
import {useRecoilValue} from 'recoil'
import Logo from '../../assets/brand.png'
import {gotoFirstPageSearch, goToHome} from '../../util/global'
import './header.scss'
import {Link} from "react-router-dom";
import {getCurrentSearch} from "../../atoms/dataAtom";

const Adv = () => <div className="advContainer">
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6596507552137958"
            crossOrigin="anonymous"></script>
    <ins className="adsbygoogle"
         style={{display: "block"}}
         data-ad-client="ca-pub-6596507552137958"
         data-ad-slot="6015691508"
         data-ad-format="auto"
         data-full-width-responsive="true"></ins>
    <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
    </script>
</div>

const Header = () => {
    const search = useRecoilValue(getCurrentSearch)
    const searchInputRef = useRef(null)
    const searchInputRefMobile = useRef(null)
    const onSearch = () => {
        const inp = (searchInputRef.current!! as HTMLInputElement)
        const currFilter = inp.value
        if (currFilter === "" || currFilter === search.filter) inp.focus()
        else gotoFirstPageSearch(search, currFilter, true)
    }
    const onSearchKeyUp = (e: KeyboardEvent) => {
        if (e.key === "Enter") onSearch()
    }

    const onMobileSearch = () => {
        const inp = (searchInputRefMobile.current!! as HTMLInputElement)
        const currFilter = inp.value
        if (currFilter === "" || currFilter === search.filter) inp.focus()
        else gotoFirstPageSearch(search, currFilter, true)
    }
    const onMobileSearchKeyUp = (e: KeyboardEvent) => {
        if (e.key === "Enter") onMobileSearch()
    }
    return <>
        <Adv/>
        <div className="headerContainer">
            <div className='header'>
                <div className='logo' onClick={goToHome}>
                    <div className="logoImg"><img src={Logo} alt="kibukibu.com logo"/></div>
                    <div className="logoText">
                        <span className="logoTextFirst">kibu</span>
                        <span className="logoTextSecond">kibu</span>
                        <span className="logoTextThird">.com</span>
                    </div>
                </div>
                <div className="search">
                    <div className="searchOverButton" onClick={onSearch}></div>
                    <input type="text" placeholder='Cerca...' defaultValue={search.filter} onKeyUp={onSearchKeyUp}
                           ref={searchInputRef}/>
                </div>
                <div className="headContact">
                    <a className="headContactLogo" href='/contact-us'>
                        <MdMailOutline color='#fff' size={20}/>
                    </a>
                    <Link className="headContactText" to='/contact-us'>Contattaci</Link>
                    <div className="headContactSocialContainer">
                        <a className='headContactSocial' href='https://www.facebook.com/kibukibucom' target="_blank"
                           rel="noreferrer">
                            <GrFacebook size={28} color="#000"/>
                        </a>
                        <a className='headContactSocial' href='https://www.instagram.com/kibukibucom/' target="_blank"
                           rel="noreferrer">
                            <GrInstagram size={28} color="#000"/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="search searchMobile">
                <div className="searchOverButton" onClick={onMobileSearch}></div>
                <input type="text" placeholder='Cerca...' defaultValue={search.filter} onKeyUp={onMobileSearchKeyUp}
                       ref={searchInputRefMobile}/>
            </div>
        </div>
    </>
}

export {Header}