import React from "react";

type PaginationComponentProps = {
    currentPage: number
    onPageClick: (page: number) => void
    totalPages: number
}

const Pagination: React.FC<PaginationComponentProps> =
    ({currentPage, onPageClick, totalPages}) => {
        const endPage = Math.min(currentPage + 3, totalPages)
        const startPage = Math.max(currentPage - 3, 1)
        const showFirstPageShortcut = startPage > 1
        const showMorePages = totalPages !== endPage
        const pages = Array.from({length: endPage - startPage + 1}, (_, i) => startPage + i)

        return <div className="listingPagination">
            {currentPage > 1 ?
                <div className="listingPaginationPage" onClick={() => onPageClick(currentPage - 1)}>&lt;</div> : null}
            {showFirstPageShortcut ? <>
                <div className="listingPaginationPage" onClick={() => onPageClick(1)}>1</div>
                <div className="listingPaginationPage">...</div>
            </> : null}
            {pages.map(p => <div
                className={`listingPaginationPage${p === currentPage ? " listingPaginationPageActive" : ""}`} key={p}
                onClick={() => onPageClick(p)}>{p}</div>)}
            {showMorePages ?
                <>
                    <div className="listingPaginationPage">...</div>
                    <div className="listingPaginationPage" onClick={() => onPageClick(totalPages)}>{totalPages}</div>
                </> :
                null
            }
            {currentPage < totalPages ?
                <div className="listingPaginationPage" onClick={() => onPageClick(currentPage + 1)}>&gt;</div> : null}
        </div>
    }

export default Pagination