import React from "react";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LineIcon,
    LineShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon
} from "react-share";
import "./shareBar.scss"

const ShareBar: React.FC<{ shareUrl: string, title: string }> = ({shareUrl, title}) =>
    <div className="share">
        <div className="shareTitle">Condividi sui social!</div>
        <div className="shareButton">
            <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={48} round/>
            </FacebookShareButton>
        </div>
        <div className="shareButton">
            <TwitterShareButton url={shareUrl} title={title}>
                <XIcon size={48} round/>
            </TwitterShareButton>
        </div>
        <div className="shareButton">
            <TelegramShareButton url={shareUrl} title={title}>
                <TelegramIcon size={48} round/>
            </TelegramShareButton>
        </div>
        <div className="shareButton">
            <WhatsappShareButton url={shareUrl} title={title} separator=" :: ">
                <WhatsappIcon size={48} round/>
            </WhatsappShareButton>
        </div>
        <div className="shareButton">
            <EmailShareButton
                url={shareUrl}
                subject={`${title} | Kibukibu.com`}
                body={`Guarda questa pagina su kibukibu.com\n\n${title}\n\n`}
            >
                <EmailIcon size={48} round/>
            </EmailShareButton>
        </div>
        <div className="shareButton">
            <LineShareButton url={shareUrl} title={title}>
                <LineIcon size={48} round/>
            </LineShareButton>
        </div>
    </div>

export default ShareBar