import React, {useEffect, useState} from 'react';
import {Location, Route, Routes, useLocation} from 'react-router-dom';
import './App.css';
import {Detail} from './pages/Detail';
import {Home} from './pages/Home';
import {Layout} from './pages/Layout';
import {Listing} from './pages/Listing';
import {useRecoilState} from 'recoil';
import {dataAtom, PageData} from './atoms/dataAtom';
import {Privacy} from "./pages/Privacy";
import {CookiePolicy} from "./pages/CookiePolicy";
import {TermsAndConditions} from "./pages/TermsAndConditions";
import {ScrollToTop} from "./util/ScrollToTop";
import {Contact} from "./pages/Contact";
import {AboutUs} from "./pages/AboutUs";
import {Categories} from "./pages/Categories";
import {Article} from "./pages/Article";
import ArticlesListingContainer from "./pages/articlesListing/ArticlesListing";

let loadedOnce = false
const getUrl: (loc: Location) => string = (loc) => {
    if (loc.pathname === "/") {
        return '/api/v1/home'
    }
    if (loc.pathname.match(/\/detail\/.*/)) {
        const toyId = loc.pathname.split("/").pop()
        return '/api/v1/toy/' + toyId
    }
    if (loc.pathname.match(/\/listing.*/)) {
        return '/api/v1/search' + loc.search
    }
    if (loc.pathname.match(/\/category\/?$/)) {
        return '/api/v1/category'
    }
    if (loc.pathname.match(/\/category.*/)) {
        const category = loc.pathname.split("/").pop()
        return '/api/v1/category/' + category
    }
    if (loc.pathname.match(/\/magazine$/)) {
        const page = loc.search
        return `/api/v1/magazine${page}`
    }
    if (loc.pathname.match(/\/magazine.*/)) {
        const pieces = loc.pathname.split("/")
        const slug = pieces[pieces.length - 1]
        return `/api/v1/magazine/${slug}`
    }

    throw new Error()
}

const loadData = async (loc: Location): Promise<PageData> => {
    if (loadedOnce) return Promise.reject()
    loadedOnce = true
    if (Object.keys(window.pageData as PageData).length > 0) {
        return Promise.resolve(window.pageData)
    } else {
        return fetch(getUrl(loc)).then(async r => await r.json())
    }
}

const App = () => {
    const [, setData] = useRecoilState(dataAtom)
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation()
    useEffect(() => {
        if (Object.keys(window.pageData as PageData).length === 0 && !isLoading) {
            setIsLoading(true)
            loadData(location).then((pd: PageData) => {
                window.pageData = pd
                setData(pd)
                setIsLoading(false)
            }).catch(() => {
            })
        } else {
            setData(window.pageData)
        }
    }, [isLoading, location, setData])


    return <>
        <ScrollToTop/>
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={<Home/>}/>
                <Route path="listing" element={<Listing/>}/>
                <Route path="category" element={<Categories/>}/>
                <Route path="category/:id" element={<Listing/>}/>
                <Route path="detail/:id" element={<Detail/>}/>
                <Route path="detail/:seo/:id" element={<Detail/>}/>
                <Route path="contact-us" element={<Contact/>}/>
                <Route path="about-us" element={<AboutUs/>}/>
                <Route path="magazine" element={<ArticlesListingContainer/>}/>
                <Route path="magazine/:slug" element={<Article/>}/>
                <Route path="legal/privacy-policy" element={<Privacy/>}/>
                <Route path="legal/cookie-policy" element={<CookiePolicy/>}/>
                <Route path="legal/terms-conditions" element={<TermsAndConditions/>}/>
            </Route>
        </Routes>
    </>
}

export default App;
